import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import ProductSection from '../components/products-section';
// import Sustainability from '../components/sustainability';
import HistorySection from '../components/history-section';

export default () => (
  <Layout>
    <Hero />
    <ProductSection />
    {/* <Sustainability /> */}
    <HistorySection />
  </Layout>
);
